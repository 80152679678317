import { useEffect, useMemo, useState, type ReactNode } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { reduxForm, type InjectedFormProps, SubmissionError, Field } from "redux-form";
import { Link, useMatch } from "react-router-dom";
import { parsePhoneNumberWithError as parsePhoneNumber } from "libphonenumber-js";

import compose from "util/compose";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { deprecatedIsInternational } from "common/form/inputs/phone/country_code";
import {
  ValidationRequirements,
  NotaryProfileInvalidFields,
  NotaryExperienceLevelEnum,
  AuthTypes,
  type AddressType,
  type Language,
} from "graphql_globals";
import { composeValidators, getFormValues } from "util/form";
import {
  validatePresence,
  validatePhoneNumberLength,
  validatePhoneNumberStartsWithValidAreacode,
  validateUSZipCode,
  validateIfAny,
  validateIf,
} from "validators/form";
import { validateEmailFormat, validatePassword } from "validators/account";
import { currentPassword, duplicateEmail } from "errors/account";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import { DeprecatedFormRow } from "common/form/elements/row";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedSelectField } from "common/form/fields/select";
import { DeprecatedPhoneNumberField } from "common/form/fields/phone/number";
import { DeprecatedPhoneCountryCodeField } from "common/form/fields/phone/country_code";
import AddressSubForm from "common/form/sub_forms/address";
import { browserTimeZone } from "util/date";
import { hardNavigateTo } from "util/navigation";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import { DeprecatedCheckboxField } from "common/form/fields/checkbox";
import { CheckboxGroup } from "common/core/form/option";
import { DeprecatedMultiSelectField } from "common/form/fields/multi_select";
import { US_STATES } from "constants/us_states";
import { useLogout } from "common/authentication";
import { useLanguageOptions } from "common/language";
import SetPassword from "common/account/fields/set_password";
import { useIAnav } from "util/feature_detection";
import { useId } from "util/html";
import type { FormError } from "errors/util";
import { customMessage } from "errors/form";
import AlertMessage from "common/core/alert_message";

import Styles from "./index.module.scss";
import UpdatePasswordMutation from "./update_password_mutation.graphql";
import type { SubmitType } from "../section_utils";
import type { NotaryProfileWizardPersonalInformation as User } from "./index_fragment.graphql";

export const PERSONAL_INFO_ROUTE = "personal-info";

type FormValues = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  postal: string;
  timezone: string;
  isAttorney: boolean | null;
  specialties: {
    legal_services: boolean;
    financial_services: boolean;
    real_estate: boolean;
    automotive_services: boolean;
    commercial_or_business_services: boolean;
    other: string | undefined;
  };
  showOtherInput: boolean;
  languages: { value: Language; label: ReactNode }[];
  experienceLevel: NotaryExperienceLevelEnum | undefined;
  currentPassword: string | undefined;
  password: string | undefined;
  countryCallingCode: string;
};
type Input = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  userPhoneNumber: string;
  countryCallingCode: string;
  address: AddressType;
  timezone: string;
  isAttorney: boolean | null;
  specialtyAreas: string[];
  languages: Language[];
  experienceLevel: NotaryExperienceLevelEnum | undefined;
};
type Props = {
  user: User;
  onNext: (input: Input) => Promise<unknown>;
  renderFooter: (handleSubmit: () => SubmitType) => ReactNode;
  showPasswordUpdate?: boolean;
};
type GetFormValueProps = {
  formValues: FormValues;
};
type InnerProps = InjectedFormProps<FormValues, Props> & Props & GetFormValueProps;

type PersonalInformationType =
  | { id: "PersonalInfo"; completed: boolean; route: typeof PERSONAL_INFO_ROUTE }
  | false;

enum StandardNotaryAreasOfSpecialty {
  FINANCIAL_SERVICES = "financial_services",
  LEGAL_SERVICES = "legal_services",
  REAL_ESTATE = "real_estate",
  AUTOMOTIVE_SERVICE = "automotive_services",
  COMMERCIAL_OR_BUSINESS_SERVICES = "commercial_or_business_services",
}
const AREAS_OF_SPECIALTY_WITH_LABELS: { title: ReactNode; value: string }[] = [
  {
    title: (
      <FormattedMessage
        id="8a1d9db2-005a-4119-83c8-b8e15a44d977"
        defaultMessage="Financial services"
      />
    ),
    value: StandardNotaryAreasOfSpecialty.FINANCIAL_SERVICES,
  },
  {
    title: (
      <FormattedMessage id="473b0b40-a648-4268-bd85-4264dfa1bd12" defaultMessage="Legal services" />
    ),
    value: StandardNotaryAreasOfSpecialty.LEGAL_SERVICES,
  },
  {
    title: (
      <FormattedMessage id="80a14cb5-7a14-452d-9c69-1aa693a9020f" defaultMessage="Real Estate" />
    ),
    value: StandardNotaryAreasOfSpecialty.REAL_ESTATE,
  },
  {
    title: (
      <FormattedMessage
        id="2cbc9b30-09ef-4701-b09d-795331f47bf3"
        defaultMessage="Automotive Services"
      />
    ),
    value: StandardNotaryAreasOfSpecialty.AUTOMOTIVE_SERVICE,
  },
  {
    title: (
      <FormattedMessage
        id="cbe28a7f-02c8-4718-9808-7b8d6401f2c2"
        defaultMessage="Commerical/Business Services"
      />
    ),
    value: StandardNotaryAreasOfSpecialty.COMMERCIAL_OR_BUSINESS_SERVICES,
  },
];

const messages = defineMessages({
  newPassword: {
    id: "c81f1d57-08a7-46a0-869f-e77eeb79b28b",
    defaultMessage: "New Password",
  },
  currentPassword: {
    id: "b45ff9f0-1f11-462e-94ff-9a3ced114dec",
    defaultMessage: "Type old password to confirm",
  },
});

function findOtherArea(user: User) {
  const { specialtyAreas } = user.notaryProfile!;
  const standardSpecialties = AREAS_OF_SPECIALTY_WITH_LABELS.map((specialty) => specialty.value);
  return specialtyAreas?.find((value) => !standardSpecialties.includes(value));
}

function languagesToMultiSelectValues(
  langs: Language[],
  allLangOptions: ReturnType<typeof useLanguageOptions>,
): FormValues["languages"] {
  return langs.flatMap((lang) => {
    const option = allLangOptions.find(({ value }) => value === lang);
    return option ? [option] : [];
  });
}

function PersonalInformation({
  user,
  initialize,
  handleSubmit,
  onNext,
  renderFooter,
  change,
  showPasswordUpdate,
  formValues,
}: InnerProps) {
  const notaryProfile = user.notaryProfile!;
  const { phoneNumber, authenticationRequirements } = user;
  const smsMFAEnabled = authenticationRequirements.some(
    (authentication) => authentication.authType === AuthTypes.SMS,
  );
  const phone = phoneNumber ? parsePhoneNumber(phoneNumber) : null;
  const { allowedTimeZones, usState } = notaryProfile;
  const timeZoneDropdownItems = useMemo(
    () => allowedTimeZones.map((tz) => ({ value: tz, label: tz.replace("_", " ") })),
    [allowedTimeZones],
  );
  const iaNavEnabled = useIAnav();
  const inSettings = Boolean(useMatch({ path: "/settings/notary/profile", end: false }));
  const showUserPersonalDetails = !iaNavEnabled || !inSettings;
  const otherValue = findOtherArea(user);
  const [showOther, setShowOther] = useState(Boolean(otherValue));
  const languageOptions = useLanguageOptions();
  const [emailOrNameTouched, setEmailOrNameTouched] = useState(false);
  const updatePasswordMutationFn = useMutation(UpdatePasswordMutation);
  const logout = useLogout();
  const intl = useIntl();
  const phoneId = useId();
  const phoneHelpId = useId();
  const params = new URLSearchParams({
    redirectUponCompletion: window.location.href,
  }).toString();

  useEffect(() => {
    const { notaryProfile } = user;

    const { address, isAttorney, specialtyAreas, languages, experienceLevel } = notaryProfile!;
    const areasOfSpecialty = specialtyAreas ?? [];
    const otherArea = findOtherArea(user);
    const tz = user.timezone || browserTimeZone();

    // state could be stored abbreviated or non abbreviated
    const officialState = US_STATES.find(
      (state) =>
        address.state.toUpperCase() === state.value.toUpperCase() ||
        address.state.trim().toUpperCase() === state.label.toUpperCase(),
    );
    initialize({
      firstName: user.firstName || "",
      middleName: user.middleName || "",
      lastName: user.lastName || "",
      countryCallingCode: phone?.countryCallingCode || "",
      phone: phone?.formatNational() || "",
      email: user.pendingEmail || "",
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      postal: address.postal,
      state: officialState?.value || "",
      timezone: tz && allowedTimeZones.includes(tz) ? tz : "",
      isAttorney,
      specialties: {
        legal_services: areasOfSpecialty.includes(StandardNotaryAreasOfSpecialty.LEGAL_SERVICES),
        financial_services: areasOfSpecialty.includes(
          StandardNotaryAreasOfSpecialty.FINANCIAL_SERVICES,
        ),
        real_estate: areasOfSpecialty.includes(StandardNotaryAreasOfSpecialty.REAL_ESTATE),
        automotive_services: areasOfSpecialty.includes(
          StandardNotaryAreasOfSpecialty.AUTOMOTIVE_SERVICE,
        ),
        commercial_or_business_services: areasOfSpecialty.includes(
          StandardNotaryAreasOfSpecialty.COMMERCIAL_OR_BUSINESS_SERVICES,
        ),
        other: otherArea,
      },
      showOtherInput: Boolean(otherArea),
      languages: languagesToMultiSelectValues(languages, languageOptions),
      experienceLevel: experienceLevel ?? undefined,
    });
  }, []);

  const serializeForm = async (fv: FormValues) => {
    const specialtyEntries = Object.entries(fv.specialties)
      .filter(([key, value]) => key !== "other" && value)
      .map(([key]) => key);

    if (showOther && typeof fv.specialties.other === "string") {
      specialtyEntries.push(fv.specialties.other);
    }

    return onNext({
      firstName: fv.firstName,
      middleName: fv.middleName,
      lastName: fv.lastName,
      email: fv.email,
      userPhoneNumber: fv.phone,
      timezone: fv.timezone,
      address: {
        line1: fv.line1,
        line2: fv.line2,
        city: fv.city,
        state: fv.state,
        postal: fv.postal,
      },
      isAttorney: fv.isAttorney,
      specialtyAreas: specialtyEntries,
      languages: fv.languages.map((language) => language.value),
      experienceLevel: fv.experienceLevel,
      countryCallingCode: fv.countryCallingCode,
    })
      .catch((error) => {
        if (
          isGraphQLError(error) &&
          error.graphQLErrors.some((e) => e.specifics === "Invalid Email for Notary")
        ) {
          return Promise.reject(
            new SubmissionError<FormValues, ReturnType<typeof duplicateEmail>>({
              email: duplicateEmail(),
            }),
          );
        }
        if (isGraphQLError(error) && error.message === "Notary profile is disabled") {
          throw new SubmissionError<FormValues, FormError>({
            firstName: customMessage({
              message: "You cannot update your name while your notary profile is disabled.",
            }),
          });
        }
      })
      .then(() => {
        if (fv.password && fv.currentPassword) {
          updatePasswordMutationFn({
            variables: {
              input: { currentPassword: fv.currentPassword, password: fv.password, logout: true },
            },
          })
            .then(() => logout())
            .then(() => hardNavigateTo("/"))
            .catch(() => {
              return Promise.reject(
                new SubmissionError<FormValues, ReturnType<typeof currentPassword>>({
                  currentPassword: currentPassword(),
                }),
              );
            });
        }
      });
  };

  return (
    <>
      <div>
        <FormattedMessage
          id="b18a928b-e5f6-43a9-86c8-daa33bdd8ccb"
          tagName="h3"
          defaultMessage="Personal Information"
        />
        {showUserPersonalDetails && (
          <FormattedMessage
            id="34ae49bc-6b21-4335-aea3-dbcb39e308f9"
            tagName="p"
            defaultMessage="First, we'll need some basic information from you. Please enter your name exactly as it appears on your commission."
          />
        )}
        <SubForm>
          <>
            {showUserPersonalDetails && (
              <>
                <SubFormSection fullWidth>
                  <FormGroup disableFormRowStyle fields={["firstName", "middleName", "lastName"]}>
                    <DeprecatedMultipartRow>
                      <FormattedMessage
                        id="3472c52e-b698-414f-920a-9f761143acac"
                        tagName="label"
                        defaultMessage="First Name"
                      />
                      <FormattedMessage
                        id="44aea251-a9a6-4d9d-9465-2142e4b4553c"
                        tagName="label"
                        defaultMessage="Middle Name"
                      />
                      <FormattedMessage
                        id="8643766c-c2c0-4b8f-b3b7-b27b46267e6f"
                        tagName="label"
                        defaultMessage="Last Name"
                      />
                    </DeprecatedMultipartRow>
                    <DeprecatedFormRow>
                      <DeprecatedMultipartRow>
                        <DeprecatedTextField
                          name="firstName"
                          useStyledInput
                          onChange={() => setEmailOrNameTouched(true)}
                        />
                        <DeprecatedTextField
                          name="middleName"
                          useStyledInput
                          onChange={() => setEmailOrNameTouched(true)}
                        />
                        <DeprecatedTextField
                          name="lastName"
                          useStyledInput
                          onChange={() => setEmailOrNameTouched(true)}
                        />
                      </DeprecatedMultipartRow>
                      <FormGroupErrors fields={["firstName", "lastName"]} />
                    </DeprecatedFormRow>
                    <p className={Styles.helpText}>
                      <FormattedMessage
                        id="5d5cc254-b4a5-41ac-be5a-858c3212bd51"
                        defaultMessage="If your commission includes your middle name or initial, you must include it here."
                      />
                    </p>
                  </FormGroup>
                </SubFormSection>

                <SubFormSection fullWidth>
                  <DeprecatedFormRow>
                    <FormattedMessage
                      id="8f8b435e-d61d-471b-9336-e385bd0f92e1"
                      defaultMessage="Email"
                      tagName="label"
                    />
                    <DeprecatedTextField
                      name="email"
                      data-automation-id="email-field"
                      placeholder="Email"
                      useStyledInput
                      onChange={() => setEmailOrNameTouched(true)}
                    />
                  </DeprecatedFormRow>
                  <FormGroupErrors fields={["email"]} />
                </SubFormSection>
              </>
            )}

            <SubFormSection fullWidth>
              <DeprecatedFormRow>
                <label htmlFor={phoneId}>
                  <FormattedMessage
                    id="ac108b34-22fe-4c34-8930-45ecae5ca817"
                    defaultMessage="Phone Number"
                  />
                </label>
                <DeprecatedMultipartRow>
                  <DeprecatedMultipartColumn width={2}>
                    <DeprecatedPhoneCountryCodeField
                      data-automation-id="phone-country-code-field"
                      id="countryCallingCode"
                      name="countryCallingCode"
                      disabled={smsMFAEnabled}
                      useStyledInputs
                    />
                  </DeprecatedMultipartColumn>
                  <DeprecatedMultipartColumn width={10}>
                    <DeprecatedPhoneNumberField
                      id={phoneId}
                      data-automation-id="phone-field"
                      aria-describedby={phoneHelpId}
                      name="phone"
                      isInternational={deprecatedIsInternational({
                        countryCode: formValues.countryCallingCode,
                      })}
                      autoComplete="tel-national"
                      disabled={smsMFAEnabled}
                      useStyledInputs
                    />
                  </DeprecatedMultipartColumn>
                  {smsMFAEnabled && (
                    <DeprecatedMultipartColumn width={1}>
                      <div className={Styles.editLink}>
                        <Link to={`/mfa_sms_setup?${params}`}>
                          <FormattedMessage
                            id="b7b28ef8-dc37-47cf-9cdd-c84aed1f908c"
                            defaultMessage="Edit"
                          />
                        </Link>
                      </div>
                    </DeprecatedMultipartColumn>
                  )}
                </DeprecatedMultipartRow>
              </DeprecatedFormRow>
              {smsMFAEnabled ? (
                <p id={phoneHelpId} className={Styles.helpText}>
                  <FormattedMessage
                    id="ac01a96f-cbd8-4869-8ee2-1035ea2c3985"
                    defaultMessage="This number will be used for SMS based MFA if enabled"
                  />
                </p>
              ) : (
                <p id={phoneHelpId} className={Styles.helpText}>
                  <FormattedMessage
                    id="d6e80b42-cbf4-4af8-ad32-ae787cba598c"
                    defaultMessage="Receive SMS messages for login and updates from the Proof team"
                  />
                </p>
              )}
              <FormGroupErrors fields={["phone"]} />
            </SubFormSection>

            <SubFormSection fullWidth>
              <DeprecatedFormRow>
                <FormGroup
                  disableFormRowStyle
                  fields={["line1", "line2", "city", "state", "postal"]}
                >
                  <FormattedMessage
                    id="6f1238ae-d4cd-4b4a-9fc9-6bf08291a413"
                    defaultMessage="Address (must be the address listed on your application with the SoS)"
                    tagName="label"
                  />
                  <AddressSubForm
                    formName="PersonalInfo"
                    useStyledInputs
                    requiredCountryCode="US"
                    change={change}
                  />
                </FormGroup>
              </DeprecatedFormRow>
            </SubFormSection>

            {showUserPersonalDetails && (
              <SubFormSection fullWidth>
                <DeprecatedFormRow>
                  <FormattedMessage
                    id="027257e4-abd6-4668-a954-32931ddbf1e7"
                    defaultMessage="Time Zone"
                    tagName="label"
                  />
                  <DeprecatedSelectField
                    id="timezone"
                    name="timezone"
                    items={timeZoneDropdownItems}
                    placeholder={
                      <FormattedMessage
                        id="f5934d06-d800-45b2-91be-e00ce1fdaedb"
                        defaultMessage="Select your time zone"
                      />
                    }
                    data-automation-id="timezone-field"
                  />
                </DeprecatedFormRow>
                <FormGroupErrors fields={["timezone"]} />
              </SubFormSection>
            )}
          </>
          <SubFormSection fullWidth>
            {usState.name === "Wisconsin" ? (
              <FormattedMessage
                id="bad2f3cd-321a-41c4-bd54-f9ed2a557be8"
                defaultMessage="Are you an attorney with a license that has never been suspended or revoked?"
                tagName="p"
              />
            ) : (
              <FormattedMessage
                id="151fb1dc-1a2c-4be7-a32a-9f4ca29b43b8"
                defaultMessage="Are you a licensed attorney?"
                tagName="p"
              />
            )}

            <DeprecatedFormRow>
              <DeprecatedRadioButtonField
                name="isAttorney"
                labelText={
                  <FormattedMessage
                    id="49444f3e-7546-4533-a533-35fd3f4aff2b"
                    defaultMessage="Yes"
                  />
                }
                radioValue
                automationId="is-attorney"
                multiline
              />
            </DeprecatedFormRow>
            <DeprecatedFormRow>
              <DeprecatedRadioButtonField
                name="isAttorney"
                labelText={
                  <FormattedMessage id="769929de-3bf0-438f-965b-d3a17f4d0c1c" defaultMessage="No" />
                }
                radioValue={false}
                automationId="not-attorney"
                multiline
              />
            </DeprecatedFormRow>
            <FormGroupErrors fields={["isAttorney"]} />
            <DeprecatedFormRow>
              <FormattedMessage
                id="a63d65a2-c812-4a03-b385-4817eaed73e6"
                tagName="p"
                defaultMessage="Next, tell us about your experience as a notary. (Optional)"
              />
              <DeprecatedFormRow>
                <FormattedMessage
                  id="35609042-ef92-42c0-b7a1-a11cab1b1585"
                  defaultMessage="How much RON experience do you have?"
                  tagName="p"
                />
                <DeprecatedFormRow>
                  <DeprecatedRadioButtonField
                    name="experienceLevel"
                    labelText={
                      <FormattedMessage
                        id="99a1d819-5e99-4547-917f-4fe5e22fe1fb"
                        defaultMessage="Less than 1 year"
                      />
                    }
                    radioValue={NotaryExperienceLevelEnum.LESS_THAN_ONE_YEAR}
                    multiline
                  />
                </DeprecatedFormRow>
                <DeprecatedFormRow>
                  <DeprecatedRadioButtonField
                    name="experienceLevel"
                    labelText={
                      <FormattedMessage
                        id="49f83efc-e77d-4cba-8844-d7702c23c0e8"
                        defaultMessage="1-3 years"
                      />
                    }
                    radioValue={NotaryExperienceLevelEnum.ONE_TO_THREE_YEARS}
                    multiline
                  />
                </DeprecatedFormRow>
                <DeprecatedFormRow>
                  <DeprecatedRadioButtonField
                    name="experienceLevel"
                    labelText={
                      <FormattedMessage
                        id="252c51c4-24d1-4a76-a1e9-8a42f13ed819"
                        defaultMessage="4-5 years"
                      />
                    }
                    radioValue={NotaryExperienceLevelEnum.FOUR_TO_FIVE_YEARS}
                    multiline
                  />
                </DeprecatedFormRow>
                <DeprecatedFormRow>
                  <DeprecatedRadioButtonField
                    name="experienceLevel"
                    labelText={
                      <FormattedMessage
                        id="2156b10b-b6e9-423f-9274-e14bd53bf603"
                        defaultMessage="5+ years"
                      />
                    }
                    radioValue={NotaryExperienceLevelEnum.MORE_THAN_FIVE_YEARS}
                    multiline
                  />
                </DeprecatedFormRow>
              </DeprecatedFormRow>
              <DeprecatedFormRow>
                <CheckboxGroup
                  label={
                    <span style={{ fontWeight: 400 }}>
                      <FormattedMessage
                        id="3c30c2a2-86a9-4d75-be98-47b28e4239e7"
                        defaultMessage="Do you have areas of specialty?"
                      />
                    </span>
                  }
                >
                  {AREAS_OF_SPECIALTY_WITH_LABELS.map(({ title, value }) => (
                    <DeprecatedCheckboxField
                      label={title}
                      key={value}
                      value={value}
                      name={`specialties.${value}`}
                      id={value}
                    />
                  ))}
                  <DeprecatedCheckboxField
                    label="Other"
                    onChange={() => {
                      showOther
                        ? change("specialties.other", undefined)
                        : change("specialties.other", findOtherArea(user));

                      setShowOther(!showOther);
                    }}
                    name="showOtherInput"
                    id="showOtherInput"
                  />
                  {showOther && (
                    <DeprecatedTextField
                      name="specialties.other"
                      placeholder={
                        <FormattedMessage
                          id="bb378029-4d30-4a52-856c-d5cbafe3a95f"
                          defaultMessage="Let us know what area you specialize in"
                        />
                      }
                      useStyledInput
                    />
                  )}
                </CheckboxGroup>
              </DeprecatedFormRow>
              <DeprecatedFormRow>
                <FormattedMessage
                  id="abde0eee-40ed-43d4-af82-20db7459ce70"
                  defaultMessage="What language(s) do you speak fluently?"
                  tagName="p"
                />
                <DeprecatedMultiSelectField
                  name="languages"
                  items={languageOptions}
                  placeholder={
                    <FormattedMessage
                      id="9d850abb-ef9b-4ae8-993e-71fa387dd38c"
                      defaultMessage="Languages"
                    />
                  }
                  autoComplete="nope"
                />
              </DeprecatedFormRow>
            </DeprecatedFormRow>
          </SubFormSection>
          {showPasswordUpdate && showUserPersonalDetails && (
            <SubFormSection fullWidth>
              <FormattedMessage
                id="9791fd71-b7db-48ae-9cef-53e40ffb52b2"
                defaultMessage="Update Password (You will need to login after changing your password.)"
                tagName="label"
              />
              <FormGroup className={Styles.passwordInput}>
                <Field
                  id="currentPassword"
                  name="currentPassword"
                  type="password"
                  component="input"
                  autoComplete="current-password"
                  aria-label={intl.formatMessage(messages.currentPassword)}
                  placeholder={intl.formatMessage(messages.currentPassword)}
                  data-automation-id="current-password-field"
                />
              </FormGroup>

              <FormGroup className={Styles.passwordInput}>
                <Field
                  aria-describedby="update-password-instructions"
                  name="password"
                  autoComplete="new-password"
                  placeholder={intl.formatMessage(messages.newPassword)}
                  aria-label={intl.formatMessage(messages.newPassword)}
                  type="password"
                  component={SetPassword}
                />
              </FormGroup>
              <FormGroupErrors fields={["password", "currentPassword"]} />
            </SubFormSection>
          )}
        </SubForm>
      </div>
      {emailOrNameTouched && notaryProfile.ial2IdentityVerified && (
        <AlertMessage kind="warning">
          <FormattedMessage
            id="062c08f9-c971-4747-afa0-bef1b258b5e9"
            defaultMessage="Changing your name or email address will require re-verification for compliance"
          />
        </AlertMessage>
      )}
      {renderFooter(handleSubmit(serializeForm))}
    </>
  );
}

export function personalInfoSection(
  lookup: Set<ValidationRequirements>,
  user: User,
): PersonalInformationType {
  const { firstName, lastName, notaryProfile, phoneNumber } = user;
  const { validation } = notaryProfile!;
  const npInputsComplete = !validation.invalidFields.includes(
    NotaryProfileInvalidFields.INVALID_ADDRESS,
  );
  const userInputsComplete = [firstName, lastName, phoneNumber].every((i) => i !== null);
  return (
    lookup.has(ValidationRequirements.PERSONAL_DETAILS) && {
      id: "PersonalInfo",
      completed: userInputsComplete && npInputsComplete,
      route: PERSONAL_INFO_ROUTE,
    }
  );
}

function validate(values: FormValues) {
  return composeValidators(
    validatePresence({ field: "firstName", label: "First Name" }),
    validatePresence({ field: "lastName", label: "Last Name" }),
    validatePresence({ field: "email", label: "Email" }),
    validateEmailFormat({ field: "email" }),
    validatePresence({ field: "timezone", label: "Time Zone" }),
    validatePresence({ field: "phone", label: "Phone Number" }),
    validatePhoneNumberLength({
      isInternational: deprecatedIsInternational({ countryCode: values.countryCallingCode }),
      field: "phone",
      label: "Phone Number",
    }),
    validateIf({
      field: "phone",
      condition: () => !deprecatedIsInternational({ countryCode: values.countryCallingCode }),
      validation: validatePhoneNumberStartsWithValidAreacode({ field: "phone" }),
    }),
    validatePresence({ field: "line1", label: "Street Address" }),
    validatePresence({ field: "city", label: "City" }),
    validatePresence({ field: "state", label: "State" }),
    validatePresence({ field: "postal", label: "ZIP" }),
    validateUSZipCode({ field: "postal" }),
    validatePresence({ field: "isAttorney", label: "Attorney status" }),
    validateIfAny({
      fields: ["currentPassword", "password"],
      condition: (value: unknown) => value,
      validation: validatePassword({ field: "password", label: "Password" }),
    }),
  )(values);
}

export default compose(
  reduxForm<FormValues, Props>({ form: "notaryProfileWizardPersonalInformation", validate }),
  getFormValues<InnerProps>("notaryProfileWizardPersonalInformation"),
)(PersonalInformation);
